<mat-tab-group>
    <mat-tab label="Manage Users">
        <app-manage-users></app-manage-users>
    </mat-tab>

    <mat-tab label="Approve Templates">
        <app-approve-templates></app-approve-templates>
    </mat-tab>

    <mat-tab label="Send Notifications">
        <app-send-notification></app-send-notification>
    </mat-tab>
</mat-tab-group>
