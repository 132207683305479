<div *ngIf="utilService.isScreenIsSmall" class="small-screen-error-div d-flex align-items-center">
    <div class="error-text-div">
        Sorry, the app doesn't support small screens...
    </div>
</div>

<div *ngIf="!utilService.isScreenIsSmall" [class.dark-theme]="isDarkThemeEnable">
    <div class="app-component">
        <header id="header" class="fixed-top header-transparent">
            <div class="container row">
                <div class="logo d-flex align-items-center">
                    <img [src]="isDarkThemeEnable ? '../assets/images/logo-dark-theme.png' : '../assets/images/logo-light-theme.png'" (click)="routeToHomeAndRefresh()">
                </div>

                <nav class="nav-menu d-none d-lg-block mr-auto d-flex align-items-center">
                    <ul>
                        <li class="d-flex align-items-center">
                            <a class="d-flex align-items-center justify-content-center" (click)="goToHome()">Home</a>
                        </li>
                        <li class="d-flex align-items-center">
                            <a class="d-flex align-items-center justify-content-center" (click)="goToTool()">Tool</a>
                        </li>
                        <li class="d-flex align-items-center">
                            <a class="d-flex align-items-center justify-content-center" (click)="goToTemplates()">Templates</a>
                        </li>
                        <li class="d-flex align-items-center">
                            <a class="d-flex align-items-center justify-content-center" (click)="goToHowToUse()">How to use</a>
                        </li>
                        <!--                        <li class="d-flex align-items-center">-->
                        <!--                            <a class="d-flex align-items-center justify-content-center" (click)="goToTutorials()">Tutorials</a>-->
                        <!--                        </li>-->
                        <!--                        <li class="d-flex align-items-center">-->
                        <!--                            <a class="d-flex align-items-center justify-content-center" (click)="goToHowToUse()">How to use</a>-->
                        <!--                        </li>-->
                        <!--                        <li class="d-flex align-items-center"><a (click)="goToPricing()">Pricing</a></li>-->
                        <li class="d-flex align-items-center" *ngIf="authService.getCurrentUserType() == 'ADMIN'">
                            <a class="d-flex align-items-center justify-content-center" (click)="goToAdminConsole()">Admin Console</a>
                        </li>
                        <li class="d-flex align-items-center" *ngIf="authService.getCurrentUserType() == 'ADMIN'">
                            <a class="d-flex align-items-center justify-content-center" (click)="goToUserStats()">User Stats</a>
                        </li>
                    </ul>
                </nav>

                <nav class="nav-menu d-none d-lg-block ml-auto d-flex align-items-center">
                    <ul>
                        <!--                        <li class="d-flex align-items-center" *ngIf="authService.getCurrentUser()"><a class="d-flex align-items-center" (click)="showProfile()"><i class='bx bx-user-circle'></i>Profile</a></li>-->
                        <li class="d-flex align-items-center" *ngIf="authService.getCurrentUser()">
                            <div class="notifications-div" appClickOutside (clickOutside)="closeNotificationsList()">
                                <mat-icon class="notifications-icon" (click)="toggleNotificationsList()">notifications_none</mat-icon>
                                <div class="notifications-count" *ngIf="userNotificationService.userUnreadNotificationCount > 0">
                                    {{userNotificationService.userUnreadNotificationCount >= 10 ? "9+" : userNotificationService.userUnreadNotificationCount}}
                                </div>
                                <div class="notifications-list-div" *ngIf="isNotificationsListVisible">
                                    <p class="no-notification-text" *ngIf="userNotificationService.userNotifications.length == 0">
                                        You don't have notifications
                                    </p>
                                    <div *ngIf="userNotificationService.userNotifications.length > 0">
                                        <div class="notifications-list-header">
                                            <button class="btn btn-primary btn-sm btn-cancel mark-all-as-read-btn" type="button" mat-button (click)="userNotificationService.markAllNotificationsAsRead()">Mark all as read</button>
                                        </div>
                                        <div class="notifications-list">
                                            <div *ngFor="let notification of userNotificationService.userNotifications">
                                                <div class="notification-item" [ngStyle]="{'background': notification.isRead ? '#ffffff' : '#63b3ff26'}" (click)="userNotificationService.markNotificationAsRead(notification)">
                                                    <div class="notification-item-subject">
                                                        <span>{{notification.subject}}</span>
                                                    </div>
                                                    <div class="notification-item-body" [innerHTML]="notification.body"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="d-flex align-items-center" *ngIf="!authService.getCurrentUser()">
                            <a class="d-flex align-items-center justify-content-center" (click)="signIn()">Sign In</a>
                        </li>
                        <li class="d-flex align-items-center" *ngIf="authService.getCurrentUser()">
                            <a class="d-flex align-items-center justify-content-center" (click)="signOut()">Sign Out</a>
                        </li>
                    </ul>
                </nav>

                <!--                <button class="theme-toggle-btn" mat-icon-button (click)="toggleTheme()" matTooltip="Light/Dark">-->
                <!--                    <mat-icon [style]="{'color':isDarkThemeEnable ? '#B5EBFF' : '#444444'}">-->
                <!--                        brightness_6-->
                <!--                    </mat-icon>-->
                <!--                </button>-->
            </div>
            <div class="app-message-div" [ngStyle]="{'background': appNotificationService.messageBarType == MESSAGE_BAR_TYPE_WARNING ? '#ffdb25' : (appNotificationService.messageBarType == MESSAGE_BAR_TYPE_ERROR ? '#ff2222' : '#50cf2a')}" *ngIf="appNotificationService.showMessageBar">
                <span>{{ appNotificationService.messageBarText }}</span>
                <button type="button" class="close pull-right" aria-label="Close" (click)="appNotificationService.showMessageBar = false">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </header>

        <router-outlet></router-outlet>

        <footer id="footer">
            <div class="container footer-container">
                <h3>Thumbz Studio</h3>
                <p>Create your Templates easier than ever...</p>
                <div class="social-links">
                    <button class="social-link" (click)="openSocialMediaLink('facebook')">
                        <mat-icon svgIcon="svg-icon-social-media-link-facebook" aria-hidden="false"></mat-icon>
                    </button>
                    <button class="social-link" (click)="openSocialMediaLink('instagram')">
                        <mat-icon svgIcon="svg-icon-social-media-link-instagram" aria-hidden="false"></mat-icon>
                    </button>
                    <button class="social-link" (click)="openSocialMediaLink('youtube')">
                        <mat-icon svgIcon="svg-icon-social-media-link-youtube" aria-hidden="false"></mat-icon>
                    </button>
                    <!--                    <button class="social-link" (click)="openSocialMediaLink('linkedin')">-->
                    <!--                        <mat-icon svgIcon="svg-icon-social-media-link-linkedin" aria-hidden="false"></mat-icon>-->
                    <!--                    </button>-->
                    <!--                    <button class="social-link" (click)="openSocialMediaLink('tiktok')">-->
                    <!--                        <mat-icon svgIcon="svg-icon-social-media-link-tiktok" aria-hidden="false"></mat-icon>-->
                    <!--                    </button>-->
                </div>
                <div class="copyright">
                    &copy; Copyright <strong><span>Thumbz Studio</span></strong>. All Rights Reserved
                </div>

                <div class="term-policy">
                    <span (click)="goToTermsOfUse()">Terms of use</span> |
                    <span (click)="goToPrivacyPolicy()">Privacy policy</span> |
                    <span (click)="goToAboutUs()">About us</span>
                </div>

                <div class="version">
                    {{ version }}
                </div>
            </div>
        </footer>
    </div>
</div>
