import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class UserStatsService {

    constructor(public http: HttpClient) {}

    public saveDownloadedImage(image: string)
    {
        return this.http.post(environment.http_endpoint + "user_stats/save_downloaded_image", image);
    }

    public getAllDownloadedImages(selectedDateRange: string)
    {
        return this.http.get(environment.http_endpoint + "user_stats/downloaded_images", {params: {dateRange: selectedDateRange}});
    }

    public getUserStatsCounts(selectedDateRange: string)
    {
        return this.http.get(environment.http_endpoint + "user_stats/counts", {params: {dateRange: selectedDateRange}});
    }
}
