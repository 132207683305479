import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ManageUsersComponent} from "./manage-users/manage-users.component";
import {AdminConsoleComponent} from "./admin-console.component";
import {MaterialModule} from "../../material.module";
import {ApproveTemplatesComponent} from './approve-templates/approve-templates.component';
import {SendNotificationComponent} from "./send-notification/send-notification.component";
import {ReactiveFormsModule} from "@angular/forms";


@NgModule({
    declarations: [
        AdminConsoleComponent,
        ManageUsersComponent,
        ApproveTemplatesComponent,
        SendNotificationComponent
    ],
    exports: [
        AdminConsoleComponent
    ],
	imports: [
		MaterialModule,
		CommonModule,
		ReactiveFormsModule
	]
})
export class AdminConsoleModule {}
