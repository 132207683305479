import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {CanvasComponentService} from '../../../../services/component-service/canvas-component.service';
import {ClipArtService} from '../../../../services/clip-art.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../../services/auth.service';
import {ToolAndGridComponentService} from "../../../../services/component-service/tool-and-grid-component.service";
import {AppNotificationService} from "../../../../services/app-notification.service";

@Component({
    selector: 'app-addnew',
    templateUrl: './add-new.component.html',
    styleUrls: ['./add-new.component.scss']
})
export class AddNewComponent implements OnInit, AfterViewInit {

    constructor(public canvasComponentService: CanvasComponentService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public clipArtService: ClipArtService,
                public appNotificationService: AppNotificationService,
                public notification: ToastrService,
                public authService: AuthService)
    { }

    @ViewChild('clip_art_upload', {static: false}) clipArtUpload: ElementRef;
    @ViewChild('add_buttons_group', {static: false}) addButtonsGroupElement: ElementRef;

    public backgroundColor;
    public shapes = [];
    public clipArts = [];
    public isShowNewText = false;
    public isShowClipArts = false;
    public isShowShapes = false;
    public isDrawingModeEnabled = false;

    public imageUrl;
    public selectedFileforUpload: any;
    public selectedShape;
    public selectedClipArt;
    public newText = "";
    public clipArtAndShapeDivPosition = {top: 0, left: 0, width: 0};

    ngOnInit()
    {
        this.getShapes();
        this.getClipArts();
    }

    ngAfterViewInit()
    {
        this.setClipArtAndShapeDivPosition();
    }

    public addNewImage(event): void
    {
        if (event && event.target && event.target.files && event.target.files[0])
        {
            const reader = new FileReader();
            reader.onload = (readerEvent) =>
            {
                this.canvasComponentService.addImage(readerEvent.target['result']);  // saves canvas state inside addImage
                this.selectedFileforUpload = null;

                this.canvasComponentService.enableCanvasKeyboardListener();
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    public addImageFromUrl(): void
    {
        if (!this.imageUrl || this.imageUrl.toString().isEmpty)
        {
            this.appNotificationService.alert("Please add an image  URL");
            return;
        }

        this.canvasComponentService.addImage(this.imageUrl); // saves canvas state inside addImage
        this.imageUrl = null;

        this.canvasComponentService.enableCanvasKeyboardListener();
    }

    public addNewItem(): void
    {
        if (this.isShowShapes && this.selectedShape)
        {
            this.canvasComponentService.addFigure(this.selectedShape.name);
            this.selectedShape = null;

            this.canvasComponentService.saveCanvasState();
            this.canvasComponentService.enableCanvasKeyboardListener();
        }
        else if (this.isShowClipArts && this.selectedClipArt)
        {
            this.canvasComponentService.addImage(this.selectedClipArt.url); // saves canvas state inside addImage
            this.selectedClipArt = null;

            this.canvasComponentService.enableCanvasKeyboardListener();
        }
        else if (this.isShowNewText)
        {
            if (!this.newText) this.newText = "Your text here";
            this.canvasComponentService.addText(this.newText);
            this.newText = null;

            this.canvasComponentService.saveCanvasState();
            this.canvasComponentService.enableCanvasKeyboardListener();
        }
    }

    public bgColorChange(): void
    {
        if (this.backgroundColor)
        {
            this.canvasComponentService.setCanvasBackground(this.backgroundColor);
        }
    }

    public colorPickerClose(): void
    {
        this.canvasComponentService.saveCanvasState();
    }

    public getClipArts(): void
    {
        this.clipArtService.getAllClipArts().subscribe((res: any[]) =>
        {
            this.clipArts = res;
        });
    }

    public showFileOpenDialog()
    {
        if (!this.clipArtUpload || !this.clipArtUpload.nativeElement) return;
        this.clipArtUpload.nativeElement.click();
    }

    public addNewClipArt(event)
    {
        if (event && event.target && event.target.files && event.target.files[0])
        {
            let file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (readerEvent) =>
            {
                let data = {
                    fileName: file.name,
                    fileData: readerEvent.target['result']
                };

                this.clipArtService.saveClipArt(data).subscribe((res) =>
                {
                    if (res)
                    {
                        this.notification.success(res['message'], 'Success');
                        this.getClipArts();
                    }
                }, (error) =>
                {
                    if (!navigator.onLine)
                    {
                        this.notification.warning("Data will be synced when you back online...", "Warning");
                        return;
                    }

                    if (error.error) this.notification.error(error.error['message'], "Error")
                });
            };
            reader.readAsDataURL(file);
            this.clipArtUpload.nativeElement.value = null;
        }
    }

    public setClipArtAndShapeDivPosition()
    {
        if (!this.addButtonsGroupElement || !this.addButtonsGroupElement.nativeElement || !this.addButtonsGroupElement.nativeElement.getBoundingClientRect()) return;

        this.clipArtAndShapeDivPosition.top = 130;
        this.clipArtAndShapeDivPosition.left = 13;
        this.clipArtAndShapeDivPosition.width = this.addButtonsGroupElement.nativeElement.getBoundingClientRect().width;
    }

    public getShapes(): void
    {
        this.shapes = [
            {
                "id": 1,
                "name": "square",
                "image": "assets/shapes/square.png"
            },
            {
                "id": 2,
                "name": "rectangle",
                "image": "assets/shapes/rectangle.png"
            },
            {
                "id": 3,
                "name": "rounded rectangle",
                "image": "assets/shapes/rounded-rectangle.png"
            },
            {
                "id": 4,
                "name": "triangle",
                "image": "assets/shapes/triangle.png"
            },
            {
                "id": 5,
                "name": "circle",
                "image": "assets/shapes/circle.png"
            },
            {
                "id": 6,
                "name": "line",
                "image": "assets/shapes/line.png"
            },
            {
                "id": 7,
                "name": "polygon",
                "image": "assets/shapes/polygon.png"
            },
            {
                "id": 8,
                "name": "star",
                "image": "assets/shapes/star.png"
            },
        ]
    }

    public showShapes(): void
    {
        this.isShowShapes = true;
        this.isShowNewText = false;
        this.isShowClipArts = false;
        this.isDrawingModeEnabled = false;
        this.canvasComponentService?.toggleDrawingMode(this.isDrawingModeEnabled);
    }

    public showClipArts(): void
    {
        this.isShowShapes = false;
        this.isShowNewText = false;
        this.isShowClipArts = true;
        this.isDrawingModeEnabled = false;
        this.canvasComponentService?.toggleDrawingMode(this.isDrawingModeEnabled);
    }

    public showAddNewText(): void
    {
        this.isShowNewText = true;
        this.isShowShapes = false;
        this.isShowClipArts = false;
        this.isDrawingModeEnabled = false;
        this.addNewItem();
        this.canvasComponentService?.toggleDrawingMode(this.isDrawingModeEnabled);
    }

    public toggleDrawingMode(): void
    {
        this.isShowShapes = false;
        this.isShowNewText = false;
        this.isShowClipArts = false;
        this.isDrawingModeEnabled = !this.isDrawingModeEnabled;

        this.canvasComponentService?.toggleDrawingMode(this.isDrawingModeEnabled);
    }
}
