<div class="notification-details-div" [formGroup]="notificationForm">

    <div class="subject-div row">
        <label class="subject-lbl col-sm-4">Subject:</label>
        <input class="subject-input col-sm-8" type="text" formControlName="subject"/>
    </div>

    <div class="body-div row">
        <label class="body-lbl col-sm-4">Body:</label>
        <textarea class="body-textarea col-sm-8" type="text" rows="5" formControlName="body" (input)="onBodyChange()"></textarea>
    </div>

    <div class="body-preview-div row">
        <label class="body-preview-lbl col-sm-4">Preview:</label>
        <div class="body-preview-html col-sm-8" [innerHTML]="bodyPreviewHtml"></div>
    </div>

    <div class="notification-type-div row">
        <label class="notification-type-lbl col-sm-4">Notification Type:</label>
        <select class="notification-type-select col-sm-8" formControlName="notificationType">
            <option *ngFor="let notificationType of ADMIN_NOTIFICATION_TYPES | keyvalue" [value]="notificationType.key">{{notificationType.value}}</option>
        </select>
    </div>

    <button class="btn btn-primary btn-sm btn-ok" [disabled]="notificationForm.invalid" type="submit" mat-button (click)="saveNotificationToSend()">Send Notification</button>
</div>
