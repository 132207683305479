export const environment = {
    http_endpoint: 'https://test-api.thumbzstudio.com/',
    ws_endpoint: 'wss://test-api.thumbzstudio.com/',
    baseUrl: 'https://www.test.thumbzstudio.com/',
    production: true,
    env: 'test',
    version: "v2.2",

    googleAnalytics_streamName: 'www.test.thumbzstudio.com',
    googleAnalytics_streamURL: 'https://www.test.thumbzstudio.com',
    googleAnalytics_streamID: '4329624030',
    googleAnalytics_measurementID: 'G-M86HRNPJQC',
};
