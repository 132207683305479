import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CanvasComponentService} from "./component-service/canvas-component.service";
import {Template} from "../models/template";

@Injectable({
	providedIn: 'root'
})
export class TemplateService {

	private currentTemplate: Template;
	private currentCanvasTypeAndSize = null;

	constructor(public http: HttpClient,
	            public canvasComponentService: CanvasComponentService)
	{ }


	public getCurrentTemplate(): Template
	{
		return this.currentTemplate;
	}

	public setCurrentTemplate(value: Template)
	{
		this.currentTemplate = value;
	}

	public getCurrentCanvasTypeAndSize()
	{
		return this.currentCanvasTypeAndSize;
	}

	public setCurrentCanvasTypeAndSize(value)
	{
		this.currentCanvasTypeAndSize = value;
	}

	public getFabricTemplate(fabricTemplateUrl)
	{
		return this.http.get(fabricTemplateUrl, {responseType: 'text', reportProgress: true});
	}

	public getTemplateCategories()
	{
		return this.http.get(environment.http_endpoint + "template/categories");
	}

	public getWelcomePageTemplates(category: string, setSize: number)
	{
		return this.http.get(environment.http_endpoint + "template/welcome_page/" + category + "/" + setSize);
	}

	public saveTemplate(data)
	{
		return this.http.post(environment.http_endpoint + "template/save_template", data);
	}

	public removeTemplate(templateId)
	{
		return this.http.post(environment.http_endpoint + "template/remove_template", templateId);
	}

	public publishTemplate(data)
	{
		return this.http.post(environment.http_endpoint + "template/publish_template", data);
	}

	public unpublishTemplate(templateId)
	{
		return this.http.post(environment.http_endpoint + "template/unpublish_template", templateId);
	}


	public createDuplicate(templateId)
	{
		return this.http.post(environment.http_endpoint + "template/create_duplicate", templateId);
	}

	public getAllGeneralTemplatesByCanvasType(canvasType: string, setIndex: number)
	{
		return this.http.get(environment.http_endpoint + "template/general_templates/" + canvasType + "/" + setIndex)
	}

	public getAllGeneralTemplates(data)
	{
		if (this.currentCanvasTypeAndSize == null) return null;

		return this.http.get(environment.http_endpoint + "template/general_templates/" + this.currentCanvasTypeAndSize['type'] + "/" + this.currentCanvasTypeAndSize['size']['width'] + "/" + this.currentCanvasTypeAndSize['size']['height'], {params: {data: JSON.stringify(data)}});
	}

	public getAllPublishedTemplates(data)
	{
		if (this.currentCanvasTypeAndSize == null) return null;

		return this.http.get(environment.http_endpoint + "template/published_templates/" + this.currentCanvasTypeAndSize['type'] + "/" + this.currentCanvasTypeAndSize['size']['width'] + "/" + this.currentCanvasTypeAndSize['size']['height'], {params: {data: JSON.stringify(data)}});
	}

	public getAllSavedTemplates(data)
	{
		if (this.currentCanvasTypeAndSize == null) return null;

		return this.http.get(environment.http_endpoint + "template/saved_templates/" + this.currentCanvasTypeAndSize['type'] + "/" + this.currentCanvasTypeAndSize['size']['width'] + "/" + this.currentCanvasTypeAndSize['size']['height'], {params: {data: JSON.stringify(data)}});
	}

	public getAllPurchasedTemplates(data)
	{
		if (this.currentCanvasTypeAndSize == null) return null;

		return this.http.get(environment.http_endpoint + "template/purchased_templates/" + this.currentCanvasTypeAndSize['type'] + "/" + this.currentCanvasTypeAndSize['size']['width'] + "/" + this.currentCanvasTypeAndSize['size']['height'], {params: {data: JSON.stringify(data)}});
	}

	public getAllFavouriteTemplates(data)
	{
		if (this.currentCanvasTypeAndSize == null) return null;

		return this.http.get(environment.http_endpoint + "template/favourite_templates/" + this.currentCanvasTypeAndSize['type'] + "/" + this.currentCanvasTypeAndSize['size']['width'] + "/" + this.currentCanvasTypeAndSize['size']['height'], {params: {data: JSON.stringify(data)}});
	}

	public getShareableCodeForTemplate(templateId: string)
	{
		return this.http.get(environment.http_endpoint + "template/get_shareable_code", {params: {templateId: templateId}});
	}

	public getTemplateFromShareableCode(shareableCode: string)
	{
		return this.http.get(environment.http_endpoint + "template/get_from_shareable_code", {params: {shareableCode: shareableCode}});
	}

	public addToFavourite(templateId)
	{
		return this.http.post(environment.http_endpoint + "template/add_to_favourite", templateId);
	}

	public removeFromFavourite(templateId)
	{
		return this.http.post(environment.http_endpoint + "template/remove_from_favourite", templateId);
	}

	public changViewCount(templateId)
	{
		return this.http.post(environment.http_endpoint + "template/change_view_count", templateId);
	}

	public changeDownloadCount(templateId)
	{
		return this.http.post(environment.http_endpoint + "template/change_download_count", templateId);
	}

	public changeLikeCount(templateId)
	{
		return this.http.post(environment.http_endpoint + "template/change_like_count", templateId);
	}
}
