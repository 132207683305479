import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {User} from '../models/user';
import {UNAUTHORIZED_PATHS} from "../util/app-consts";
import {ToastrService} from "ngx-toastr";
import {LocalStorageService} from "./local-storage.service";
import {AppNotificationService} from "./app-notification.service";
import {ToolAndGridComponentService} from "./component-service/tool-and-grid-component.service";
import {UserNotificationService} from "./user-notification.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private currentUser: User = null;

    constructor(public http: HttpClient,
                public notification: ToastrService,
                public appNotificationService: AppNotificationService,
                public toolAndGridComponentService: ToolAndGridComponentService,
                public localStorageService: LocalStorageService,
                public userNotificationService: UserNotificationService)
    {
    }

    public getCurrentUser(): User
    {
        return this.currentUser;
    }

    public getCurrentUserType(): string
    {
        if (!this.currentUser)
        {
            return null;
        }
        else
        {
            return this.currentUser.role;
        }
    }

    public isUserLoggedIn(): boolean
    {
        return this.getCurrentUser() != null;
    }

    public getUserDataByAuthToken(token)
    {
        this.setAuthToken(token);
        this.getUserDataByAuthTokenInLocalStorage();
    }

    public getUserDataByAuthTokenInLocalStorage()
    {
        this.getUserData().subscribe((userData) =>
        {
            if (userData)
            {
                let user = new User(userData['id'], userData['name'], userData['firstName'], userData['lastName'], userData['email'], userData['profilePictureUrl'], userData['role'], userData['joinedDate'], userData['loginCount']);
                if (user.equals(this.currentUser)) return;

                this.currentUser = user;
                this.notification.success("Successfully Signed in...", 'Success');

                this.toolAndGridComponentService.refreshAllPublishedTemplates();
                this.toolAndGridComponentService.refreshAllSavedTemplates();
                this.toolAndGridComponentService.refreshAllFavouriteTemplates();

                this.userNotificationService.connect(this.getAuthToken(), this.getCurrentUser()?.email);
            }
            else
            {
                this.appNotificationService.showAskToLoginWarningMessage();
            }
        }, () =>
        {
            this.appNotificationService.showAskToLoginWarningMessage();
        });
    }

    public signOut()
    {
        this.setAuthToken(null);
        this.currentUser = null;
        this.notification.success("Successfully Signed out...", 'Success');

        this.userNotificationService.disconnect();
    }

    public getAuthToken()
    {
        return this.localStorageService.loadAuthToken();
    }

    public setAuthToken(authToken)
    {
        this.localStorageService.saveAuthToken(authToken);
    }

    public getUserData()
    {
        return this.http.get(environment.http_endpoint + "auth/user_data");
    }

    public isUnAuthorizedUrl(url: string): boolean
    {
        for (let path of UNAUTHORIZED_PATHS)
        {
            if (path == url) return true;
        }
        return false;
    }
}
