import { Injectable, OnDestroy } from '@angular/core';
import { Client, Frame, StompConfig } from '@stomp/stompjs';
import { jwtDecode } from 'jwt-decode';
import { UserNotification } from "../models/user-notification";
import { environment } from "../../environments/environment";

@Injectable({
	providedIn: 'root'
})
export class UserNotificationService implements OnDestroy {

	private WEB_SOCKET_END_POINT: string = environment.ws_endpoint + 'ws_endpoint';
	private QUEUE_PATH: string = '/queue/notification';

	private stompClient: Client;
	private authToken: string;
	private userEmail: string;

	public userUnreadNotificationCount = 0;
	public userNotifications: UserNotification[] = [];

	constructor() {}

	ngOnDestroy(): void {
		this.disconnect();
	}

	public connect(authToken: string, userEmail: string) {
		this.authToken = authToken;
		this.userEmail = userEmail;

		const stompConfig: StompConfig = {
			brokerURL: this.WEB_SOCKET_END_POINT + '?access_token=' + authToken,
			reconnectDelay: 200,
			onConnect: () => {
				const subscriptionDestination = this.QUEUE_PATH + '/' + userEmail;
				this.stompClient.subscribe(subscriptionDestination, (message: Frame) => {
					this.onNotificationsReceived(JSON.parse(message.body));
				});

				const publishSubscriptionDestination = '/ws_endpoint/user/' + userEmail + '/subscribe';
				this.stompClient.publish({
					destination: publishSubscriptionDestination,
					body: JSON.stringify({ subscription: subscriptionDestination })
				});
			},

			onWebSocketError: () => {
				if (this.isTokenExpired()) {
					this.stompClient.forceDisconnect();
					this.stompClient.deactivate();

					console.log('WebSocket Connection Disconnected');
				}
			}
		};

		this.stompClient = new Client(stompConfig);
		this.stompClient.activate();

		console.log('WebSocket Connection Initialized');
	}

	public disconnect() {
		if (this.stompClient !== null) {
			this.stompClient.forceDisconnect();
			this.stompClient.deactivate();
		}
		console.log('WebSocket Connection Disconnected');
	}

	private onNotificationsReceived(userNotifications: UserNotification[]) {
		this.userNotifications = userNotifications;
		this.userUnreadNotificationCount = userNotifications.filter(notification => !notification.isRead).length;
	}

	public markAllNotificationsAsRead() {
		this.userNotifications.forEach(notification => notification.isRead = true);
		this.userUnreadNotificationCount = 0;
		this.sendReadReceipt(this.userNotifications.map(notification => notification.id));
	}

	public markNotificationAsRead(notification: UserNotification) {
		if(notification.isRead) return;

		notification.isRead = true;
		this.userUnreadNotificationCount = this.userNotifications.filter(notification => !notification.isRead).length;
		this.sendReadReceipt([notification.id]);
	}

	private sendReadReceipt(notificationIdList: number[]) {
		const publishDestination = '/ws_endpoint/user/' + this.userEmail + '/read_receipt';
		this.stompClient.publish({
			destination: publishDestination,
			body: JSON.stringify(notificationIdList)
		});
	}

	public isTokenExpired() {
		if (!this.authToken) return true;
		try {
			const decodedToken = jwtDecode(this.authToken);
			const currentTime = Date.now() / 1000;
			return decodedToken.exp < currentTime;
		} catch (error) {
			console.error('Error decoding token:', error);
			return true;
		}
	}
}
