import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {AdminConsoleService} from "../../../services/admin-console.service";
import {ToastrService} from "ngx-toastr";

@Component({
	selector: 'app-send-notification',
	templateUrl: './send-notification.component.html',
	styleUrls: ['./send-notification.component.scss']
})
export class SendNotificationComponent {

	public ADMIN_NOTIFICATION_TYPES: Map<string, string> = new Map([
		['ADMIN_TO_ALL_USERS', 'To All Users']
	]);

	public notificationForm = new FormGroup({
		subject: new FormControl('', [Validators.required, Validators.maxLength(500)]),
		body: new FormControl('', [Validators.required, Validators.maxLength(10000)]),
		notificationType: new FormControl('', [Validators.required])
	});

	public bodyPreviewHtml: SafeHtml;

	constructor(private sanitizer: DomSanitizer,
	            public adminConsoleService: AdminConsoleService,
	            public notification: ToastrService) { }

	public saveNotificationToSend() {
		this.adminConsoleService.saveNotificationToSend(this.notificationForm.getRawValue()).subscribe(() => {
			this.notification.success('Notification saved to send successfully', 'Success');
        }, (error) => {
			this.notification.error(error?.error['message'], 'Error');
		});
	}

	public onBodyChange() {
        this.bodyPreviewHtml = this.sanitizer.bypassSecurityTrustHtml(this.notificationForm.get('body').value);
    }
}
