import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class AdminConsoleService {


    constructor(public http: HttpClient) { }

    public getAllUsers()
    {
        return this.http.get(environment.http_endpoint + "admin_console/all_users");
    }

    public promoteUserToDesigner(userId)
    {
        return this.http.post(environment.http_endpoint + "admin_console/promote_user_as_designer", userId);
    }

    public demoteUserToBronzeMember(userId)
    {
        return this.http.post(environment.http_endpoint + "admin_console/demote_user_to_bronze_member", userId);
    }

    public getAllPublishedTemplatesToApprove()
    {
        return this.http.get(environment.http_endpoint + "admin_console/published_templates_to_approve");
    }

    public approveTemplate(templateId: string)
    {
        return this.http.post(environment.http_endpoint + "admin_console/approve_template", templateId);
    }

    public declineTemplate(templateId: string)
    {
        return this.http.post(environment.http_endpoint + "admin_console/decline_template", templateId);
    }

    public saveNotificationToSend(data)
    {
        return this.http.post(environment.http_endpoint + "admin_console/save_notifications_to_send", data);
    }
}
