import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DivDisableDirective} from "./div-disable.directive";
import {KeyRegisterDirective} from "./key-register.directive";
import {DecimalDirective} from "./decimal.directive";
import {MandatoryFieldDirective} from "./mandatory-field.directive";
import {ClickOutsideDirective} from './click-outside.directive.directive';


@NgModule({
    declarations: [
        DivDisableDirective,
        KeyRegisterDirective,
        DecimalDirective,
        MandatoryFieldDirective,
        ClickOutsideDirective,
    ],
    exports: [
        DivDisableDirective,
        KeyRegisterDirective,
        DecimalDirective,
        MandatoryFieldDirective,
        ClickOutsideDirective,
    ],
    imports: [
        CommonModule
    ]
})

export class DirectivesModule {
}
